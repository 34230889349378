import React, { useContext, useState } from 'react';
import AppDataContext from 'app/app-data-context';
import Button from 'react-bootstrap/Button';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';
import Icon from '@nguyengg/lambdajs/components/icon';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SmartNavLink from 'components/smart-nav-link';
import styles from './index.module.scss';
import useAuthLinks from '@nguyengg/lambdajs/auth-links';
export default function AppNavbar() {
    const { user } = useContext(AppDataContext);
    const [shownCanvas, setShownCanvas] = useState(false);
    const [loginHref, logoutHref] = useAuthLinks();
    return (React.createElement(Navbar, { id: "navbar", className: styles.navbar, collapseOnSelect: true, expand: false, fixed: "top", variant: "dark", onSelect: () => setShownCanvas(false) },
        React.createElement(Container, { className: "d-none d-md-flex justify-content-center", fluid: true },
            React.createElement(Navbar.Brand, { className: styles.brand, href: "/" }, "Hera Nails & Spa")),
        React.createElement(Container, { className: "justify-content-between justify-content-md-center" },
            React.createElement(Nav, { className: "d-none d-md-flex flex-row" },
                React.createElement(SmartNavLink, { className: "px-3", href: "/" }, "Home"),
                React.createElement(SmartNavLink, { className: "px-3", href: "/services" }, "Services"),
                React.createElement(SmartNavLink, { className: "px-3 text-muted", disabled: true, href: "/reservations", title: "Under construction" }, "Reservations"),
                React.createElement(SmartNavLink, { className: "px-3 text-muted", disabled: true, href: "/rewards", title: "Under construction" }, "Rewards"),
                React.createElement(SmartNavLink, { className: "px-3", href: "/gallery" }, "Gallery")),
            React.createElement(Navbar.Brand, { className: classNames('d-flex d-md-none', styles['brand']), href: "/" }, "Hera Nails & Spa"),
            React.createElement(Button, { "aria-controls": "navigation_sidebar", className: styles['toggler'], variant: "light", onClick: () => setShownCanvas(true) },
                React.createElement(Icon, { name: "list" }))),
        React.createElement(Navbar.Offcanvas, { id: "navigation_sidebar", className: styles['nav-sidebar'], placement: "end", restoreFocus: true, show: shownCanvas, onHide: () => setShownCanvas(false) },
            React.createElement(Offcanvas.Header, { className: "justify-content-end" },
                React.createElement(Button, { className: classNames('d-flex d-md-none', styles['toggler']), variant: "light", onClick: () => setShownCanvas(false) },
                    React.createElement(Icon, { name: "x-lg" }))),
            React.createElement(Offcanvas.Body, { className: "text-center" },
                React.createElement(Nav, null,
                    React.createElement("div", null,
                        React.createElement(Header, null, "Account"),
                        user ? (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "py-2" },
                                React.createElement("div", null,
                                    React.createElement(Navbar.Text, null, user.name || '-')),
                                user.phoneNumber && (React.createElement(Navbar.Text, null,
                                    "***-***-",
                                    user.phoneNumber.substring(Math.max(0, user.phoneNumber.length - 4))))),
                            React.createElement(Nav.Link, { href: logoutHref }, "Sign out"))) : (React.createElement(Nav.Link, { href: loginHref }, "Sign in"))),
                    React.createElement("hr", null),
                    React.createElement("div", null,
                        React.createElement(Header, null, "Pages"),
                        React.createElement(SmartNavLink, { href: "/" }, "Home"),
                        React.createElement(SmartNavLink, { href: "/services" }, "Services"),
                        React.createElement(SmartNavLink, { className: "text-muted", disabled: true, href: "/reservations", title: "Under construction" }, "Reservations"),
                        React.createElement(SmartNavLink, { className: "text-muted", disabled: true, href: "/rewards", title: "Under construction" }, "Rewards"),
                        React.createElement(SmartNavLink, { href: "/gallery" }, "Gallery")),
                    React.createElement("hr", null),
                    React.createElement("div", null,
                        React.createElement(Header, null, "Legal"),
                        React.createElement(Nav.Link, { href: "/policies/privacy.html" }, "Privacy Policy"),
                        React.createElement(Nav.Link, { href: "/policies/cookies.html" }, "Cookies Policy"),
                        React.createElement(Nav.Link, { href: "/policies/terms.html" }, "Terms and Conditions")))))));
}
function Header({ children }) {
    return React.createElement("span", { className: "heading pb-2" }, children);
}
